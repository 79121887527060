<template>
<span>
  Button Slot: {{status}}
</span>
</template>

<script>
export default {
  data: () => ({
    status: "vacant",
  }),
  computed: {
    // https://stackoverflow.com/questions/43750569/how-to-watch-only-one-object-in-an-array
    device: function(){
      return this.$devices[this.$deviceID]
    }
  },
  watch: {
    device: {
      handler: function(newVal){
        console.log("$devices changed!", newVal)
//        this.status = newVal.slot.status
        if (newVal.commandSlot){
          if (newVal.commandSlot.status) {
            this.status = newVal.commandSlot.status
          }
        }
      },
      deep: true
    },
  }
}
</script>