/* eslint no-unused-vars: 0 */
import IdToken from 'vue2-faui-user-fe/src/mixins/IdToken'
var sprintf = require('sprintf-js').sprintf,
    vsprintf = require('sprintf-js').vsprintf

export default {
  async handle(message, devices, deviceID){
    console.log('updated', message)
    this.$message = message

    if (message.deviceID in devices){
      console.log("deviceID", message.deviceID, deviceID)
      console.log("message", message)
      switch (message.subtype){
        case 'fetchInterval':
          var newInterval = message.newInterval
          console.log("newInterval",newInterval)
          devices[message.deviceID].storedInfo.Attr.runtime_settings.interval = newInterval
          break
        case 'buttonlog':
          {
            const idToken = await IdToken.methods.getIdToken()
            const url = sprintf("https://connect-srv.uedasoft.com/getbuttonlogs/%s/%s",message.deviceID, idToken)
            const res = await fetch(url)
            if (res.status == 200) {
              const data = await res.json()
              console.log("buttonlogs", data)
              devices[message.deviceID].storedInfo.Attr.ButtonLogs = data
            }
          }
          break
      }
    }
  }
}