<template>
  <div>
<!--
    <slot name="title">
            Periocid Fetch
    </slot>
-->
            <v-expansion-panels flat>
              <v-expansion-panel>
              <v-expansion-panel-header>
                Next Collection: {{secondsUntilExpectedNextConnection}} sec
              </v-expansion-panel-header>
              <v-expansion-panel-content>
<!--              <v-card>
                <v-card-title>Periodic Connection</v-card-title>
                <v-card-text> -->
                  <v-list
                    subheader
                  >
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Now</v-list-item-title>
                        {{now}}
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Estimated</v-list-item-title>
                        {{expectedNextConnectedTime}}
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Last fetch</v-list-item-title>
                        {{lastConnectedTime}}
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Fetch interval set value</v-list-item-title>
                        {{interval}} sec
                      </v-list-item-content>
                    </v-list-item>

                  </v-list>
              </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
  </div>
</template>

<script>
import Devices from '@/mixins/devices'

export default {
  mixins: [Devices],
//  props:["deviceID"],
  data: () => ({
/*    deviceID: "",*/
    now: new Date(),
  }),
  computed: {
    selectedDeviceID: function(){
/*      if (this.deviceID){
        return this.deviceID
      } else {
        return this.$deviceID
      }*/
      return this.$deviceID
    },
    lastConnectedTime: function(){
/*      if (this.deviceID){
        console.log("deviceID", this.deviceID)
        let selectedDevice = this.$devices[this.deviceID]
        return selectedDevice.lastConnection.time.toString()
      }*/
      if (this.$deviceID){
        let selectedDevice = this.$devices[this.$deviceID]
        if (selectedDevice) {
          return selectedDevice.lastConnection.time.toString()
        } else {
          return ""
        }
      } else {
        return ""
      }
    },
    secondsUntilExpectedNextConnection: function(){
/*      if (this.deviceID) {
        if (!(this.$devices[this.deviceID] === undefined)){
          let selectedDevice = this.$devices[this.deviceID]
//          console.log("selectedDevice",selectedDevice)
          return Math.floor(selectedDevice.lastConnection.time/*.getTime()*//*/1000)
                 + selectedDevice.storedInfo.Attr.runtime_settings.interval
                 - Math.floor(this.now.getTime()/1000)
        } else {
          return ""
        }
      }*/
      if (this.$deviceID) {
        if (!(this.$devices[this.$deviceID] === undefined)){
          let selectedDevice = this.$devices[this.$deviceID]
//          console.log("selectedDevice",selectedDevice)
          return Math.floor(selectedDevice.lastConnection.time/*.getTime()*//1000)
                 + selectedDevice.storedInfo.Attr.runtime_settings.interval
                 - Math.floor(this.now.getTime()/1000)
        } else {
          return ""
        }
      } else {
        return ""
      }
    },
    expectedNextConnectedTime: function(){
/*      if (this.deviceID) {
        if (!(this.$devices[this.deviceID] === undefined)){
          let selectedDevice = this.$devices[this.deviceID]
          console.log("selectedDevice",selectedDevice)
          return new Date(selectedDevice.lastConnection.time/*.getTime()*//*
           + (selectedDevice.storedInfo.Attr.runtime_settings.interval * 1000))
        } else {
          return ""
        }
      }*/
      if (this.$deviceID) {
        if (!(this.$devices[this.$deviceID] === undefined)){
          let selectedDevice = this.$devices[this.$deviceID]
          console.log("selectedDevice",selectedDevice)
          console.log('selectedDevice.lastConnection.time',selectedDevice.lastConnection.time)
          console.log('selectedDevice.storedInfo.Attr.runtime_settings.interval * 1000', selectedDevice.storedInfo.Attr.runtime_settings.interval * 1000)
          let now = new Date(selectedDevice.lastConnection.time)
          return new Date(now.setSeconds(now.getSeconds() + selectedDevice.storedInfo.Attr.runtime_settings.interval))
        } else {
          return ""
        }
      } else {
        return ""
      }
    },
      interval: function(){
/*      if (this.deviceID) {
        let selectedDevice = this.$devices[this.deviceID]
        console.log("selectedDevice",selectedDevice)
        return selectedDevice.storedInfo.Attr.runtime_settings.interval
      }*/
      if (this.$deviceID) {
        let selectedDevice = this.$devices[this.$deviceID]
        console.log("selectedDevice",selectedDevice)
        if (selectedDevice){
          return selectedDevice.storedInfo.Attr.runtime_settings.interval
        } else {
          return ""
        }
      } else {
        return ""
      }
    },
  },
  mounted() {
    setInterval(()=>{
      this.now = new Date()
//      console.log("aho")
    }, 100);
  },
}
</script>