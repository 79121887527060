// To issue Websocket message

//import * as firebase from 'firebase'
import IdToken from 'vue2-faui-user-fe/src/mixins/IdToken'

export default {
  mixins: [IdToken],
  methods: {
    /**
     * send requestLastConnectTime message
     * 
     * @param {*} deviceID 
     */
    async sendRequestLastConnectTimeMessage(deviceID){
      console.log("requestLastConnectTime message sending")
      const idToken = await this.getIdToken()

      // message
      let message = {
        type: "requestLastConnectTime", 
        param: null, 
        needResponseFlag: false, 
        deviceID: deviceID,
        idToken: idToken
      }
      let messageJson = JSON.stringify(message)
      console.log("messageJson", messageJson)

      // send
      this.$ws.send(messageJson)
    },
    /**
     * send Command Message
     * 
     * @param {*} param
     * @param {*} needResponseFlag
     */
    async sendCommandMessage(param, needResponseFlag){
      console.log("command message sending")
      console.log("param",param)

      // idToken
      const idToken = await this.getIdToken()

      // message
      let message = { type: "command", 
                      param: param, 
                      needResponseFlag: needResponseFlag, 
                      deviceID: this.$deviceID,
                      idToken: idToken
                    }
      let messageJson = JSON.stringify(message)
      console.log("messageJson", messageJson)

      // send
      this.$ws.send(messageJson)
    },
    /**
     * send peer connection request message
     * 
     * @param {*} deviceID 
     */
    async sendPeerConnectRequestMessage(deviceID){
      const idToken = await this.getIdToken()
      console.log("PeerConnectRequest message sending")
      this.initPeerConnection()

      // message
      let message = {
        type: "peerConnectRequest", 
        param: null, 
        needResponseFlag: true, 
        deviceID: deviceID,
        idToken: idToken
      }
      let messageJson = JSON.stringify(message)
      console.log("messageJson", messageJson)

      // send
      this.$ws.send(messageJson)

    },
    /**
     * send updateSettings message
     * 
     * @param {*} deviceID 
     * @param {Array} settings
     */
     async sendUpdateSettingsMessage(deviceID, settings){
      const idToken = await this.getIdToken()
      console.log("PeerConnectRequest message sending")

      // message
      let message = {
        type: "updateSettings", 
        param: settings, 
        needResponseFlag: true, 
        deviceID: deviceID,
        idToken: idToken
      }
      let messageJson = JSON.stringify(message)
      console.log("messageJson", messageJson)

      // send
      this.$ws.send(messageJson)
    },
    /**
     * send messageCompleted message
     * 
     * @param {*} deviceID 
     */
     async sendMessageCompletedMessage(deviceID){
      const idToken = await this.getIdToken()

      // message
      let message = {
        type: "messageCompleted",
        needResponseFlag: false, 
        deviceID: deviceID,
        idToken: idToken
      }
      let messageJson = JSON.stringify(message)
      console.log("messageJson", messageJson)

      // send
      this.$ws.send(messageJson)      
    },
  }
}