<template>
  <div>
    connectionState: {{IceConnectionState}}

    <v-progress-linear
      v-if="progressLinear"
      indeterminate
      color="primary"
    ></v-progress-linear>

    <div id="Xterm" ref="Xterm"></div>
    $isLogin: {{$isLogin}}
    peerConnection: {{peerConnection}}
<!--    peerConnection.iceConnectionState: {{peerConnection.iceConnectionState}}-->

  </div>
</template>

<script>
//import Xterm from '@/mixins/xterm'

export default {
  props: ['IceConnectionState', 'peerConnection'],
  data: () => ({
    progressLinear: false,
  }),
  mounted: function(){
    console.log("IceConnectionState",this.IceConnectionState)
    console.log("peerConnection",this.peerConnection)
  }
}
</script>
