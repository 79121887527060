<template>
  <div>
    <v-card flat>
      <v-card-title>
        Connection Status
      </v-card-title>
      <v-card-text>
        <ConnectionStatus class="mb-6" noConnectButton/>
      </v-card-text>
    </v-card>

    Queries: {{$route.query}}
    
    <v-tabs v-model="tab_model" v-if="$deviceID">
      <v-tab href="#fetchfreq">fetch frequency</v-tab>
      <v-tab href="#command">Command</v-tab>
      <v-tab href="#Monitor">Monitor</v-tab>
      <v-tab href="#server">Server</v-tab>

      <v-tab-item value="tab-0">
      </v-tab-item>
    </v-tabs>

    <v-tabs-items v-model="tab_model" v-show="$deviceID">
      <v-tab-item value="fetchfreq">
        <FetchFrequancy
          ref="fetchfrequency"
        />
      </v-tab-item>

      <v-tab-item value="fetchfreq">
      </v-tab-item>

    </v-tabs-items>
  </div>
</template>

<script>
//import ConnectionStatus from '@/components/Device/ConnectionStatus'
import FetchFrequancy from '@/components/Device/Settings/FetchFrequency/FetchFrequancy'
import ConnectionStatus from '@/components/Device/ConnectionStatus'
export default {
  components: {
    ConnectionStatus, 
    FetchFrequancy, 
  },
  data: () => ({
    tab_model: "fetchfreq",
  }),
  mounted(){
    if (this.$deviceID != ""){
      this.$refs.fetchfrequency.refresh(this.$devices[this.$deviceID].storedInfo)
    }
    if ("tab" in this.$route.query){
      this.tab_model = this.$route.query.tab
    }
  },
  watch: {
    $deviceID: function(newVal){
      console.log("this.$refs", this.$refs)
      console.log("this.$refs.fetchfrequency", this.$refs.fetchfrequency)
      if (newVal != ""){
        this.$refs.fetchfrequency.refresh(this.$devices[newVal].storedInfo)
      }
    }
  },
}
</script>
