<template>
  <v-card>
    <v-card-title>
      News
    </v-card-title>
    <v-card-text>
      <span>2022.08.09</span>
      <div v-html="md.render(this.contents)"/>
    </v-card-text>
  </v-card>
</template>

<script>
var MarkdownIt = require('markdown-it')
, fm = require('tiny-frontmatter');
//  , fm = require('front-matter')
// var fm = require('gray-matter')
export default {
  data: () => ({
    md: new MarkdownIt({
      html: true,
//      break: true,
    }),
    news: "# still in development"
  }),
  computed: {
    contents: function(){
      console.log("content.attributes",fm(this.news).attributes)
      return fm(this.news).body
    }
  },

}
</script>