<template>
  <v-app>

<!-- Navigation bar -->
    <Navbar :links="links">
      <template v-slot:title>
        <Title/>
      </template>
      
      <template v-slot:menu>
        <v-spacer></v-spacer>
        <Menu/>
      </template>
    </Navbar>

<!-- Login hidden component -->
    <Login
      tosUrl="https://connect.uedasoft.com/docs/tos.html"
      privacyPolicyUrl="https://connect.uedasoft.com/docs/privacy.html"
    />
    <p class="text-h4 ma-2">{{$route.name}}</p>
<!-- main content --> 
    <v-main v-if="this.$isLogin">
      <router-view></router-view>
    </v-main>
    <News v-else/>

  </v-app>
</template>

<script>
import {AccountRoutes as AccountRoutes} from 'vue2-faui-user-fe/account'
//import {PurchaseRoutes as PurchaseRoutes} from 'vue2-faui-user-fe/purchase'
import {Login as Login} from 'vue2-faui-user-fe/login'
import {Navbar} from 'vuetify-nav'
//import UUID from 'uuidjs'
import Title from '@/components/Navbar/Title'
import Menu from '@/components/Navbar/Menu'
import Websocket from '@/mixins/websoket'

import News from '@/components/News'

export default {
  name: 'App',
  components: { Navbar, Login, Title, Menu, News },
  mixins: [Websocket],
  data: () => ({
    ws: "", //new WebSocket("wss://connect-srv.uedasoft.com/ws"),
    links: [
      { icon: 'home', text: 'Home', route: '/'},
      { icon: 'home', text: 'Button', route: '/button'},
      { icon: 'home', text: 'Apps', route: '/apps'},
      { icon: 'home', text: 'Xterm', route: '/xterm'},
      { icon: 'home', text: 'Devices', route: '/devices'},
      { icon: 'home', text: 'Buy', route: '/buy'},
//      PurchaseRoutes.menuItem,
      AccountRoutes.menuItem,
      { icon: 'menu_book', text: 'Document', route: '/docs'},
    ]
  }),
  watch: {
    $displayName: function(newVal){
      console.log("$displayName",newVal)
    }
  },
  mounted: function(){
    console.log("App.window", window)
  }
};
</script>
