// for Global Mixin
// https://v2.vuejs.org/v2/guide/mixins.html?redirect=true#Global-Mixin

import Vue from 'vue'

export default {
  init: () => {
    let globalData = new Vue({
      data: {
        /**
         *  current selected device
         */
//        $device: "",

        /** 
         * ID of selected device
         */
        $deviceID: "",

        /**
         * array of devices which are currently waiting the response
         * 
         *   // this.$devices[item.ID] ={response: "", lastConnectTime: 0}
         *   this.$devices[item.ID] = {storedInfo: item, lastConnection: {message: "", time: 0}}
         */
        $devices: [],
      }
    });
    let computed = {
      $device: //{
        function(){
          if (globalData.$data.$deviceID === null) {
            return {}
          } else {
            return globalData.$data.$devices[globalData.$data.$deviceID]
          }
        },
//        get: function () { return globalData.$data.$device },
//        set: function (newDevice) { globalData.$data.$device = newDevice; }
//      },
      $deviceID: {
        get: function () { return globalData.$data.$deviceID },
        set: function (newDeviceID) { globalData.$data.$deviceID = newDeviceID; }
      },
      $devices: {
        // this.$devices[item.ID] = {storedInfo: item, lastConnection: {message: "", time: 0}}
        get: function () { return globalData.$data.$devices },
        set: function (newDevices) { globalData.$data.$devices = newDevices; }
      },
    };
    return computed
  },
}