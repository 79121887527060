<template>
  <div>
    <v-container>
    <v-row>
    Device: <v-spacer/><b>{{$deviceID ? $deviceID: "Not Selected"}}</b>
    <v-spacer/>
    <v-btn 
      x-small
      dark
      rounded
      color="primary"
      @click="$refs.deviceselect.open()"
    >
      select
    </v-btn>

    </v-row>
    </v-container>
    <DeviceSelectDialog
      ref="deviceselect"
    />
  </div>
</template>

<script>
import DeviceSelectDialog from './DeviceSelect/DeviceSelectDialog'
export default {
  components: {
    DeviceSelectDialog,
  },
  
}
</script>