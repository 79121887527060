<template>
  <div ref="Xterm"></div>
</template>

<script>
// for using xterm.js

//import 'xterm/css/xterm.css'
//import { Terminal } from 'xterm'
import { FitAddon } from 'xterm-addon-fit'
import { WebLinksAddon } from 'xterm-addon-web-links'
import { Unicode11Addon } from 'xterm-addon-unicode11'

export default {
  methods: {
    refreshXterm(){
      // https://github.com/xtermjs/xterm.js/blob/4.14.1/typings/xterm.d.ts#L891
      this.term.dispose()
      this.newXterm()
    },
    newXterm(){
      this.term = new window.Terminal();
//      this.term.open(document.getElementById('Xterm'));
      this.term.open(this.$refs.Xterm)
      let fitAddon = new FitAddon()
      this.term.loadAddon(fitAddon)
      this.term.loadAddon(new WebLinksAddon())
      this.term.loadAddon(new Unicode11Addon())
      this.term.unicode.activeVersion = '11'
      fitAddon.fit()
  
      // Xterm onData
      this.term.onData(data => {
        console.log("onData",data)
        this.xtermChannel.send(data)
      })
      // Xterm onBinary
      this.term.onBinary(data => {
        console.log("onBinary",data)
      })
      // Xterm onKey
      this.term.onKey(data => {
        console.log("onKey",data)
      })
    },
    createXtermDataChannel(){
      console.log("createXtermDataChannel started")
      this.xtermChannel = this.$PeerConnection.createDataChannel('xterm')

      // DataChannel onOpen
      this.xtermChannel.onopen = () => {
        console.log('xtermChannel has opened')
      }
      // DataChannel onMessage
      this.xtermChannel.onmessage = e => {
        this.term.write(String.fromCharCode.apply("", new Uint8Array(e.data)))
//        console.log(e)
      }
      // DataChannlel onClose
      this.xtermChannel.onclose = e => {
        console.log(`DataChannel '${this.xtermChannel.label}' id '${this.xtermChannel.label}' is closed.`)
        console.log(e)

        // reset IceConnectionStete
//        this.$IceConnectionState = ""

        // refresh Xterm
        this.refreshXterm()
      }
      // DataChannel onError
      this.xtermChannel.onerror = e => {
        console.log(`Error ${e.message} occured at DataChannel '${this.xtermChannel.label}' id '${this.xtermChannel.label}'`)
        console.log(e)
      }
    },
    sendToChannel(message){
      if (this.xtermChannel){
        this.xtermChannel.send(message)
      } else {
        console.log("this.xtermChannel", this.xtermChannel)
      }
    }
  },
  watch: {
    $IceConnectionState: function(newVal){
      if (newVal == "connected" || newVal == "completed" ){
        this.refreshXterm()
        this.createXtermDataChannel()
      } else if (newVal == "disconnected"){
        // refresh Xterm
//        this.refreshXterm()
      }
    }
  },
  mounted(){
    console.log("this.term",this.term)
    if (!this.term){
      console.log("newXterm")
      this.newXterm()
    }
    console.log("this.term after",this.term)

    if (this.$IceConnectionState == "connected" || this.$IceConnectionState == "completed"){
      this.createXtermDataChannel()
    }
  }
  
}
</script>

<style>
@import 'https://cdn.jsdelivr.net/npm/xterm@4.16.0/css/xterm.css';
</style>