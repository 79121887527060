<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    eager
  >
    <v-card>
      <v-card-text>
        <DeviceList
          @selectedDeviceActionSelectorDialog="close"
          @selectDeviceInfoDialog="close"
          ref="DeviceList"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          dark
          rounded
          color="primary"
          @click="request"
        >
          Request One
        </v-btn>
        <v-spacer/>
        <v-btn
          dark
          rounded
          color="primary"
          @click="close"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>

</template>

<script>
import DeviceList from './DeviceList/DeviceList.vue'
export default {
  components: {
    DeviceList
  },
  data () {
    return {
      dialog: false,
    }
  },
  methods: {
    open(){
      this.dialog = true
      this.$refs.DeviceList.updateItems()
    },
    /**
     * Handler of left hand side FAB
     */
    request(){
      this.$refs.DeviceList.requestOne()
    },
    /**
     * Handler of right hand side FAB
     */
    close(){
      this.dialog = false
    },
  },
}
</script>