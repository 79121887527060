// For Websocket connection

//import * as firebase from 'firebase'
import IdToken from 'vue2-faui-user-fe/src/mixins/IdToken'
import Message from '@/mixins/message'

import UpdateHandler from '@/exports/messagehandlers/updated'

export default {
  mixins: [IdToken, Message],
  methods: {
    wsStart: async function(){
      const idToken = await this.getIdToken()
      console.log("WebSocket Connection Start")
      this.$ws = new WebSocket("wss://connect-srv.uedasoft.com/ws/"+idToken)
      this.$ws.onerror = function(event) {
        console.error("Grobal WebSocket error observed:", event);
      };
      this.$ws.onclose = (event) => {
        console.log("Grobal WebSocket is closed:", event);
        this.wsStart()
      };
      this.$ws.onopen = (event) => {
        console.log(event)
        console.log("Grobal WebSocket is opened As:",this.$ws)
        console.log("this.$devices",this.$devices)
        Object.keys(this.$devices).forEach(deviceID => {
          console.log("deviceID", deviceID)
          this.sendRequestLastConnectTimeMessage(deviceID)
        })
      };
      this.$ws.onmessage = async (event) => {
        console.log("Grobal WebSocket onmessage event:",event)
        let message = JSON.parse(event.data)
        console.log("Grobal WebSocket message", message)
        switch(message.type){
          case 'response':
            // save response
            this.$response = message
            console.log("deviceID", message.deviceID)
            console.log("response", this.$response)

            // request to delete messageBox
//            this.deleteMessageBoxRequest()
            this.sendMessageCompletedMessage(this.$deviceID)
            break
          case 'busy':
            console.log("message box busy")
            this.progressLinear = false
            break
          case 'peerConnectionReady':
            this.peerConnectionOffer()
            break
          case 'offerDeviceSDP':
            console.log("deviceSDP", message.sdp)
            try {
              this.$PeerConnection.setRemoteDescription(new RTCSessionDescription(JSON.parse(atob(message.sdp))))
              //this.ws.close(1000, "Work complete"); // https://ja.javascript.info/websocket#ref-943
            } catch (e) {
              alert(e)
            }
            break
          case 'lastConnectTime':
            {
              this.$devices[message.deviceID].lastConnection.time = new Date(message.lastConnectTime * 1000)
            }
            console.log("lastConnectTime", this.$devices[message.deviceID].lastConnection.time.toString())
            break
          case 'slotGetOccupied':
            console.log("slotGetOccupied", message)
            this.$devices[message.deviceID].slot.status = "occupied"
//            this.$set(this.$devices[message.deviceID], 'slot', {status: "occupied"})
//            this.$set(this.$devices, message.deviceID, this.$devices[message.deviceID])
            break
          case 'slotGetVacant':
            console.log("slotGetVacant", message)
            this.$devices[message.deviceID].slot.status = "vacant"
//            this.$set(this.$devices[message.deviceID], 'slot', {status: "vacant"})
//            this.$set(this.$devices, message.deviceID, this.$devices[message.deviceID])
            break
          case 'occupied':
            console.log('occupied', message)
            this.$devices[message.deviceID].commandSlot.status = "occupied"
            break
          case 'released':
            console.log('released', message)
            this.$devices[message.deviceID].commandSlot.status = "vacant"
            break
          case 'updated':
            UpdateHandler.handle(message, this.$devices, this.$deviceID)
            /*
            if (message.subtype == "buttonlog") {
//              const idToken = await user.getIdToken()
              const url = sprintf("https://connect-srv.uedasoft.com/getbuttonlogs/%s",message.deviceID)
              const res = await fetch(url)
              if (res.status == 200) {
                const data = await res.json()
                console.log("data", data)
              }
            }*/
            break
          }
      }
    },
    peerConnectionOffer: async function(){
      const idToken = await this.getIdToken()

      // https://stackoverflow.com/a/61339398/11073131
      this.$root.sendChannel = this.$PeerConnection.createDataChannel('send')
      this.$root.sendChannel.onclose = () => {
        console.log('sendChannel has closed')
        this.$IceConnectionState = this.$PeerConnection.iceConnectionState
      }
      this.$root.sendChannel.onopen = () => {
        console.log('sendChannel has opened')
        this.progressLinear = false
      }
      this.$root.sendChannel.onmessage = e => console.log(`Message from DataChannel '${this.$root.sendChannel.label}' payload '${e.data}'`)
      this.$PeerConnection.onconnectionstatechange = e => {
        console.log("onconnectionstatechange", this.$PeerConnection.connectionState, e)
        this.$IceConnectionState = this.$PeerConnection.iceConnectionState
      }
      this.$PeerConnection.oniceconnectionstatechange = e => {
        console.log("OnIceconnectionstatechange")
        console.log("event", e)
        console.log("iceConnectionState",this.$PeerConnection.iceConnectionState)
        this.$IceConnectionState = this.$PeerConnection.iceConnectionState
      }
      this.$PeerConnection.onicecandidate = (event) => {
        console.log("event", event)
        if (event.candidate === null) {
          //sdp := btoa(JSON.stringify(this.$PeerConnection.localDescription))
          console.log("this.ws",this.ws)
          console.log("ice candidate:", btoa(JSON.stringify(this.$PeerConnection.localDescription)))

          // message
          let message = {
            type: "offerBrowserSDP",
            sdp:  btoa(JSON.stringify(this.$PeerConnection.localDescription)), 
            needResponseFlag: true, 
            deviceID: this.$deviceID,
            idToken: idToken
          }
          let messageJson = JSON.stringify(message)
          console.log("messageJson", messageJson)
    
          // send
          this.$ws.send(messageJson)    
        }
      }
      this.$PeerConnection.onnegotiationneeded = e => {
        console.log(e)
        this.$PeerConnection.createOffer().then(d => this.$PeerConnection.setLocalDescription(d)).catch(console.error)
      }
      this.$PeerConnection.oniceconnectionstatechange = e => {
        console.log("OnIceconnectionstatechange")
        console.log("event", e)
        console.log("iceConnectionState",this.$PeerConnection.iceConnectionState)
      }
    },
/*
    deleteMessageBoxRequest: async function(){
      const idToken = await this.getIdToken()

      // message
      let message = {
        type: "deleteMessageBoxRequest",
        needResponseFlag: false, 
        deviceID: this.$deviceID,
        idToken: idToken
      }
      let messageJson = JSON.stringify(message)
      console.log("messageJson", messageJson)

      // send
      this.$ws.send(messageJson)      
    },
*/
  },
  watch: {
    $isLogin: function(newVal){
      if (newVal == true){
        this.wsStart()
      }
    }
  }
}