import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
//import Vuetify from 'vuetify'
import fbinit from 'vue2-faui-user-fe/fbinit'
import initialize from 'vue2-faui-user-fe/initialize'
import ws from '/src/mixins/globals/ws.js'
import signaling from '/src/mixins/globals/signaling.js'
import device from '/src/mixins/globals/device.js'

Vue.config.productionTip = false

/*
*  Global settings
*/
Vue.prototype.$server = 'https://connect-srv.uedasoft.com'

/*
*  Initializations
*/

console.log("location.href", location.href)
console.log("location.pathname",location.pathname)

// firebase
if (process.env.VUE_APP_loginAvailable == "true"){
  fbinit.fbinit()
} else if (location.pathname == '/backdoor'){
  fbinit.fbinit()
}

// websocket connection
Vue.mixin({
  computed: ws.init()
})

// webrtc signaling
Vue.mixin({
  computed: signaling.init()
})

// selected device
Vue.mixin({
  computed: device.init()
})

// login accounts and purcase
Vue.mixin({
  computed: {
    ...initialize.accountinit(false),
//    ...initialize.purchaseinit()
  }
})

/*
Vue.use(window.Vuetify);
//const vuetify = new window.Vuetify({
const vuetify = new Vuetify({
});
*/

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')

console.log("window", window)