// For Webrtc signaling

//import * as firebase from 'firebase'
import IdToken from 'vue2-faui-user-fe/src/mixins/IdToken'
import Message from '@/mixins/message'

export default {
  mixins: [IdToken,Message],
  methods: {
    initPeerConnection: function(){
      this.$PeerConnection = new RTCPeerConnection({ // https://jsfiddle.net/9tsx15mg/90/
        iceServers: [
          {
            urls: 'stun:stun.l.google.com:19302'
          }
        ]
      })
    },
    signalingStart: async function(){
      this.progressLinear = true
      this.sendPeerConnectRequestMessage(this.$deviceID)
    },
    peerdisconnect: function(){
      this.$PeerConnection.close()
    },
  },
  watch: {
    "$PeerConnection.iceConnectionState": function(newVal, oldVal){
      console.log("newPeerConnection", newVal)
      console.log("oldPeerConnection", oldVal)
    },
    $IceConnectionState: function(newVal){
      console.log("new $connectionState", newVal)
      if (newVal == "connected"){
        this.progressLinear = false
      }
    }
  },
  mounted(){
    this.$root.iceConnectionState = ""
    console.log("$isLogin",this.$isLogin)
  }
}