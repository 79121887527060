<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    eager
  >
    <v-card>
      <v-card-title>
        {{deficeID}}
      </v-card-title>
      <v-card-text>
<!--        <pre>
        {{JSON.stringify(item,null,2)}}
        </pre> -->
        <v-container>
          <h4>Basic info</h4>
          <v-row no-gutters>
            <v-col cols="4">Created</v-col><v-col>{{createdTime}}</v-col>
          </v-row>
        </v-container>

        <v-container v-if="isActivated">
          <h4>Activated device</h4>
          <v-row no-gutters  :v-if="isActivated">
            <v-col cols="4">OS</v-col><v-col :v-if="isActivated">{{OS}}</v-col>
          </v-row>
          <v-row no-gutters :v-if="isActivated">
            <v-col cols="4">Device Type</v-col><v-col>{{DeviceType}}</v-col>
          </v-row>
          <v-row no-gutters :v-if='isActivated && isRPi'>
            <v-col cols="4">RPi serial</v-col><v-col>{{psn}}</v-col>
          </v-row>
          <v-row no-gutters :v-if='isActivated && isRPi'>
            <v-col cols="4">RPi SD Card</v-col><v-col>{{cid}}</v-col>
          </v-row>
        </v-container>

        <v-container v-if="isActivated">
          <h4>Software</h4>
          <v-row no-gutters  :v-if="isActivated">
            <v-col cols="4">Version</v-col><v-col>{{version}}</v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          dark
          rounded
          color="primary"
          @click="close"
        >
          Close
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
//import SaveDevice from '@/mixins/savedevice'
//import Devices from '@/mixins/devices'

export default {
//  mixins: [SaveDevice, Devices],
  data () {
    return {
      dialog: false,
      item: {},
    }
  },
  methods: {

    /**
     * Open this dialog
     * @param object item An item of the list.
     */
    open(item){
      console.log("item", item)
      this.item = item

//      this.refreshDevice(item)

      /** open dialog */
      this.dialog = true

    },

    /**
     * Handler of left hand side FAB
     */
    close(){
      this.dialog = false
      this.$emit('closed')
    }
  },
  computed: {
    deficeID() {
      return this.item.ID
    },
    createdTime: function(){
      console.log("this.item",this.item)
      if ('Attr' in this.item){
        let dateTime = new Date(this.item.Attr.createdtime * 1000);
        return dateTime.toString()
      } else {
        return ""
      }
    },
    isActivated(){
      if (!this.item){return false}
      if (!('Attr' in this.item)){return false}
      return this.item.Attr.activated
    },
    isRPi(){
      if ('deviceIDs' in this.item.Attr && 'uname' in this.item.Attr.deviceIDs) {
        return this.isActivated && (this.item.Attr.deviceIDs.uname[1] == "raspberrypi")
      } else {
        return ""
      }
    },
    OS(){
      if (!this.isActivated){
        return ""
      } else {
        if ('deviceIDs' in this.item.Attr && 'uname' in this.item.Attr.deviceIDs) {
          return this.item.Attr.deviceIDs.uname[0]
        } else {
          return ""
        }
      }
    },
    DeviceType(){
      if (!this.isActivated){
        return ""
      } else {
        if ('deviceIDs' in this.item.Attr && 'uname' in this.item.Attr.deviceIDs) {
          return this.item.Attr.deviceIDs.uname[1]
        } else {
          return ""
        }        
      }
    },
    psn(){
      if (!this.isActivated){
        return ""
      } else if (this.DeviceType != "raspberrypi"){
        return ""
      } else {
        return this.item.Attr.deviceIDs.psn
      }
    },
    cid(){
      if (!this.isActivated){
        return ""
      } else if (this.DeviceType != "raspberrypi"){
        return ""
      } else {
        return this.item.Attr.deviceIDs.cid
      }
    },
    version(){
      if (!this.isActivated){
        return ""
      } else {
        return this.item.Attr.deviceIDs.version
      }
    }
  }
}
</script>
