<template>
  <div>
    <v-card>
      <SelectedDevice
        class="ma-2"
      />
    </v-card>
    <v-textarea
      v-model="model"
      auto-grow
    >
    </v-textarea>
    <v-btn
    @click="saveDevice(model)"
    >
      Save
    </v-btn>
    <v-btn
    @click="setdeviceruntimesettings(model)"
    >
      setdeviceruntimesettings
    </v-btn>
  </div>
</template>

<script>
import SelectedDevice from '@/components/Device/SelectedDevice/SelectedDevice'
import SaveDevice from '@/mixins/savedevice'
export default{
  mixins: [
    SaveDevice
  ],
  components: {
    SelectedDevice,
  },
  data() {
    return {
      model: "",
    }
  },
  mounted: function(){
    console.log("mounted")
    this.model = JSON.stringify(this.$devices[this.$deviceID],null,2)
  },
  watch: {
    $deviceID: function(newVal){
      if (newVal != ""){
        this.model = JSON.stringify(this.$devices[newVal],null,2)
      }
    }
  },

}
</script>
