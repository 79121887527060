<template>
  <div>
    <span class="text-subtitle-2">P2P Direct:  </span>
    <span>{{isConnecting ? "Connecting" : "Not Connected"}}</span>
    <v-progress-linear
      v-if="progressLinear"
      indeterminate
      color="primary"
    ></v-progress-linear>
    <v-row>
      <v-btn
        dark
        rounded
        color="primary"
        @click="changeConnectionStatus"
        class="ml-auto mb-4 mt-3 mr-4"
        v-if="this.$deviceID && !noConnectButton"
      >
        {{isConnecting ? "disconnect" : "connect"}}
      </v-btn>
    </v-row>

  </div>
</template>

<script>
import Signaling from '@/mixins/signaling'
export default {
  mixins: [Signaling],
  props:{
    noConnectButton: {type: Boolean, default: false}
  },
  data: () => ({
    progressLinear: false,
  }),
  methods:{
    changeConnectionStatus(){
      if (this.isConnecting) {
        this.peerdisconnect()
      } else {
        this.signalingStart()
      }
    },
  },
  computed: {
    isConnecting: function(){
      if (this.$IceConnectionState == "connected" || this.$IceConnectionState == "completed"){
        return true
      } else {
        return false
      }
    },  
  },

}
</script>