<template>
  <v-card-text class="red--text">
    Once you delete this account, there is no going back. Please be certain.<br>
    This account is used by the following services:
      <p class="font-weight-bold ma-0" v-for = "serviceName in usingProjects" :key="serviceName">
        - {{serviceName}}
      </p>
    If you delete this account here, your account will be deleted and initialized for all those services.
  </v-card-text>
</template>

<script>
import IdToken from 'vue2-faui-user-fe/src/mixins/IdToken'
export default {
  mixins: [IdToken],
  data: () => ({
    usingProjects: []
  }),
  async mounted (){
    const idToken = await this.getIdToken()
    console.log("idToken", idToken)
    const url = "http://parsifal.uedasoft.com:10014/getlistofserviceusing/" + idToken
    fetch(url)
    .then(response => response.json())
    .then(data => {
      console.log(data)
      this.usingProjects = data.result
    });
  },
}
</script>