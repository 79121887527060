// For save device

//import * as firebase from 'firebase'
import IdToken from 'vue2-faui-user-fe/src/mixins/IdToken'

export default {
  mixins: [IdToken],
  methods: {
    saveDevice: async function(jsonStr){
      const deviceInfo = JSON.parse(jsonStr)
      const storedInfo = deviceInfo.storedInfo
      const storedInfoStr = JSON.stringify(storedInfo)
      await this.saveStoredInfoStr(storedInfoStr)
    },    
    saveSelectedDevice: async function(){
      if (!this.$deviceID){
        console.log("$deviceID is null")
      } else {
        const storedInfoStr = JSON.stringify(this.$devices[this.$deviceID].storedInfo)
        await this.saveStoredInfoStr(storedInfoStr)
      }
    },
    saveStoredInfoStr: async function(storedInfoStr){
      const idToken = await this.getIdToken()
      const url="https://connect-srv.uedasoft.com/updatedevice/"+idToken
      const res = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: storedInfoStr,
      })
      if (res.status == 200) {
        console.log("save completed")
      }
    },
    setdeviceruntimesettings:async function(){
      const idToken = await this.getIdToken()
      const url="https://connect-srv.uedasoft.com/setdeviceruntimesettings/"+idToken
      const res = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(
          {
          interval: 60,
          minInterval: 10
          }
  
        ),
      })
      if (res.status == 200) {
        console.log("setdeviceruntimesettings completed")
      }
    }
  },
}