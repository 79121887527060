import Vue from 'vue';
//import Vuetify from 'vuetify/lib/framework';
import Vuetify from 'vuetify';

Vue.use(Vuetify);

export default new Vuetify({
});

console.log("Vuetify.window", window)
