<template>
  <div>
    <v-card>
      <v-card-text>
        <p class="text-h5">Fetch Frequency</p>
        deviceID: {{deviceID}}
        <InputFrequency
          ref="fetchfrequency"
        />
      </v-card-text>
      <v-card-actions>
                <v-btn
          color="primary"
          text
          @click="cancel"
        >
          Cancel
        </v-btn>
        <v-spacer/>
        <v-btn
          absolute
          dark
          bottom
          right
          rounded
          color="primary"
          @click="update"
        >
          Update
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import InputFrequency from '@/components/Device/Settings/FetchFrequency/InputFrequency'
import Message from '@/mixins/message'
import Devices from '@/mixins/devices'


export default {
  name: "Internal",
  mixins: [Message, Devices],
  components: {
    InputFrequency,
  },
  data: function () {
    return {
      edit: true,
      item: "",
    };
  },
  methods: {
    refresh(item) {
      console.log("item", item)
      this.item = item
      for (const components in this.$refs) {
        this.$refs[components].refreshDevice(item)
      }

    },
    update() {
      /** @type  {{Path: string, Value: any}[]}*/
      var updatingSettings = [];
      console.log("updatingSettings",updatingSettings)

      console.log("update")
      console.log("refs", this.$refs)

      /** update item by all the value of eddit components */
      console.log("values", Object.values(this.$refs))
      for (const components in this.$refs) {
        console.log("components", components)
        if (typeof this.$refs[components].update == 'function'){
          this.$refs[components].update(updatingSettings)
        }
      }
      console.log("updatingSettings",updatingSettings)

      /** save item */
      if (updatingSettings.length){
        this.sendUpdateSettingsMessage(this.$deviceID,updatingSettings )
      }
    },
    cancel(){
      for (const components in this.$refs) {
        this.$refs[components].refresh(this.item)
      }
    }
  },
  computed: {
    deviceID(){
      return this.item.ID
    }
  },
  mounted: function(){
    console.log("this.edit", this.edit)
  },
  watch: {
    device: function(newVal){
      console.log("device", newVal)
    }
  },

};
</script>