<template>
  <div>
    <v-card flat>
      <v-card-title>
        Connection Status
      </v-card-title>
      <v-card-text>
        <ConnectionStatus class="mb-6" noConnectButton/>
      </v-card-text>
    </v-card>

    <v-card flat v-show="this.$deviceID">
      <v-card-title>Buttons</v-card-title>
      <v-card-text>
      <v-progress-linear
        v-if="progressLinear"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <CommandSlot ref="commandSlot" class= "ma-2"/>
      <v-container class="lighten-5">
        <v-row class="ma-4" v-for='(button, index) in buttons' :key="index">
          <v-btn
            block
            rounded
            :disabled='$refs.commandSlot.status == "occupied"'
            @click="cmd(index, false)"
            v-if='mode == "normal"'
          >
            {{button.name}}
          </v-btn>
          <v-btn
            block
            rounded
            v-if='mode == "edit"'
          >
            name:<v-text-field
              v-model="buttons[index].name"
            />
            <v-switch
            v-model="buttons[index].needResponseFlag"
            label="needResponseFlag"
          ></v-switch>
          </v-btn>
        </v-row>
      </v-container>
      </v-card-text>

      <v-card-actions v-if="mode == 'normal'">
          <v-btn 
            text
            @click='mode = "edit"'
          >
            edit
          </v-btn>
      </v-card-actions>

      <v-card-actions v-if="mode == 'edit'">
          <v-btn 
            text
            @click="buttonCancel"
          >
            cancel
          </v-btn>
          <v-btn 
            text
            @click="buttonUpdate"
          >
            update
          </v-btn>
      </v-card-actions>
    </v-card>

    <v-card flat v-show="this.$deviceID" >
      <v-card-title>Log</v-card-title>
      <v-card-text>
        <div v-for="log in deviceButtonLogs" :key="log.index">
          <p class="text-caption mb-0">{{(new Date(log.unixtime * 1000)).toString() }}</p>
          <p class="text-body-1 mt-0">Button {{log.buttonID}} is {{buttonlogaction(log.type)}}.</p>
        </div>
      </v-card-text>
    </v-card>

    <v-card flat v-show="this.$deviceID" >
      <v-card-title>Result</v-card-title>
      <v-card-text>
        <v-textarea
          v-model="model"
          style="font-family: 'Roboto Mono', monospace !important;"
        >
        </v-textarea>
        <div style="font-family: 'Roboto Mono', monospace; white-space: pre-wrap">
          {{model}}
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
/* eslint no-unused-vars: 0 */
import CommandSlot from  '@/components/Device/CommandSlot'
import Message from '@/mixins/message'
import SaveDevice from '@/mixins/savedevice'
import ConnectionStatus from '@/components/Device/ConnectionStatus'
import IdToken from 'vue2-faui-user-fe/src/mixins/IdToken'
var sprintf = require('sprintf-js').sprintf,
    vsprintf = require('sprintf-js').vsprintf

export default {
  components: {
    CommandSlot,
    ConnectionStatus,
  },
  data: () => ({
    progressLinear: false,
    model: "",
    mode: "normal",
    buttons: [],
  }),
  mixins: [
    Message,
    SaveDevice,
    IdToken
  ],
  methods: {
    cmd: async function(param, needResponseFlag){

      // clear response
      this.model = ""

      // send Command Message
      this.sendCommandMessage(param, needResponseFlag)
    },
    buttonsRefresh: function(){
      this.buttons = []
      if (this.$deviceID){
        this.buttons.push(...this.$devices[this.$deviceID].storedInfo.Attr.buttons)
      }
    },
    buttonCancel: function(){
      this.buttonsRefresh()
      this.mode = "normal"
    },
    buttonUpdate: function(){
      this.$devices[this.$deviceID].storedInfo.Attr.buttons = this.buttons
      this.saveSelectedDevice()
      this.mode = "normal"
    },
    /*
    updateButtonLogs: async function(message){
      if (message.subtype == "buttonlog") {
        const idToken = await this.getIdToken()
        const url = sprintf("https://connect-srv.uedasoft.com/getbuttonlogs/%s/%s",message.deviceID, idToken)
        const res = await fetch(url)
        if (res.status == 200) {
          const data = await res.json()
          console.log("buttonlogs", data)
          this.buttonlogs = data
        }
      }
    },*/
    buttonlogaction: n =>{
      switch(n){
        case 0:
          return "pushed"
        case 1:
          return "collected by server"
        case 2:
          return "executed on your device"
        default:
          return ""
      }
    }
  },
  computed: {
    deviceButtonLogs: function(){
      if (this.$deviceID && (this.$deviceID in this.$devices)) {
        return this.$devices[this.$deviceID].storedInfo.Attr.ButtonLogs
      } else {
        return []
      }
    },
  },
  watch: {
    $response: {
      handler : function(newVal){
        console.log("newVal.deviceID",newVal.deviceID )
        console.log("this.$deviceID",this.$deviceID)
        if (newVal.deviceID == this.$deviceID){
          console.log("newVal",newVal)
          console.log("newVal.responseByteArrayBase64Str",newVal.responseByteArrayBase64Str)
          this.model = atob(newVal.responseByteArrayBase64Str)
        }
        console.log("$responseStr", this.model)
      },
      deep: true
    },
    $deviceID: function(newVal){
      this.buttonsRefresh()
//      this.buttonlogs = this.$devices[newVal].storedInfo.Attr.ButtonLogs
    },
/*    $message: function(newVal){
      console.log("new message", newVal)
      this.updateButtonLogs(newVal)
    }*/
  },
  mounted: function(){
    this.buttonsRefresh()
/*    if (this.$deviceID){
      this.buttonlogs = this.$devices[this.$deviceID].storedInfo.Attr.ButtonLogs
    }*/
  }
}
</script>