<template>
  <div>
    <v-list>
      <v-subheader>Command List</v-subheader>
      <!--
      <v-list-item>
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>

        <v-list-item-title>Home</v-list-item-title>
      </v-list-item>
      -->

<!--      <v-list-group
        :value="true"
        prepend-icon="mdi-account-circle"
      > --> 
      <v-list-group
        prepend-icon="mdi-account-circle"
      >
        <template v-slot:activator>
          <v-list-item-title>maintenance </v-list-item-title>
        </template>

        <v-list-group
          no-action
          sub-group
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Strage</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="([title, action], i) in strage"
            :key="i"
            link
            @click="sendCommand(action)"
          >
            <v-list-item-title v-text="title"></v-list-item-title>
          </v-list-item>
        </v-list-group>

      </v-list-group>
    </v-list>
    <div id="xterm">
      <Xterm ref="xterm"/>
    </div>
  </div>
</template>

<script>
import Xterm from '@/views/Xterm.vue'
export default {
  components: {
    Xterm,
  },
  data: () => ({
    strage: [
      ['ls', 'ls -la\nps\n'],
    ],
  }),
  methods: {
    sendCommand(cmdStr){
      var element = document.getElementById("xterm");
      element.scrollIntoView();
      this.$refs.xterm.sendToChannel(cmdStr)
    }
  }
}
</script>

<style>

</style>