// To handle this.$devices
export default {
  methods: {
    createNewEntryWithStoredInfo(storedInfo){
      if (! (storedInfo.ID in this.$devices)){
        this.$set(this.$devices, 
          storedInfo.ID, 
        { storedInfo: storedInfo, 
          lastConnection: {message: "", time: 0}, 
          commandSlot: {status:"けろけろ"}
        })
      } else {
        this.$set(this.$devices[storedInfo.ID], 'storedInfo', storedInfo)
      }
    },
    refreshDevice(device){
      console.log("device", device)
      this.device = device
      this.deviceID = device.ID
      for (const components in this.$refs) {
        // if exist "refreshDevice"
        if (typeof this.$refs[components].refreshDevice == 'function'){
          this.$refs[components].refreshDevice(device)
        }
      }
    }
  },
}