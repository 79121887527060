<template>
  <div>
    <v-btn v-if="$user_id == 'J83qdzowZDMtS3HRTPawIC221rj1'" @click="$router.push('Management')">
      Management
    </v-btn>
<!--    <RouterLink v-if="$user_id == 'J83qdzowZDMtS3HRTPawIC221rj1'" to="Management">
        Management
    </RouterLink> -->
    <News/>
  </div>

</template>

<script>
import News from '@/components/News'
import IdToken from 'vue2-faui-user-fe/src/mixins/IdToken'


export default {
  name: 'Home',
  mixins: [IdToken],
  components: {
    News
  },
  data: () => ({
  }),
  methods: {
    async deleteuser(){
      const idToken = await this.getIdToken()
      console.log("idToken", idToken)
      const url = "http://parsifal.uedasoft.com:10014/getlistofserviceusing/" + idToken
      fetch(url)
      .then(response => response.json())
      .then(data => console.log(data));
    }
  },
  updated() {
  }
}
</script>
