<template>
  <div>
    <SelectedDevice
      class="ml-6 mr-2"
    />
    <NextCollection v-if="this.$deviceID"/>
    <DirectConnection  v-if="this.$deviceID" class="ml-6 mb-2" :noConnectButton="noConnectButton"/>
  </div>
</template>

<script>
//import Direct from '@/components/Device/ConnectionStatus/Direct'
import NextCollection from '@/components/Device/NextCollection'
import DirectConnection from '@/components/Device/DirectConnection'
import SelectedDevice from '@/components/Device/SelectedDevice/SelectedDevice'
//import Signaling from '@/mixins/signaling'
export default {
  name: 'ConnectionStatus',
  props:{
    noConnectButton: {type: Boolean, default: false}
  },
//  mixins: [Signaling],
  components: {
//    Direct,
    NextCollection,
    DirectConnection,
    SelectedDevice,
  },
  data: () => ({
    panel: "",
    now: new Date(),
    progressLinear: false,
  }),
/*
  methods:{
    changeConnectionStatus(){
      if (this.isConnecting) {
        this.peerdisconnect()
      } else {
        this.signalingStart()
      }
    },
    kerokero(){
      alert("aho")
    }
  },
  computed: {
    isConnecting: function(){
      if (this.$IceConnectionState == "connected" || this.$IceConnectionState == "completed"){
        return true
      } else {
        return false
      }
    },  
  },
*/
  mounted() {
    setInterval(()=>{
      this.now = new Date()
    }, 100);
  },
/*
  computed: {
    isPanelOpened: function(){
      if (this.panel === 0){
        return true
      } else {
        return false
      }
    },
    isConnecting: function(){
      if (!("directConnection" in this.$refs)){
        return false
      } else {
        return this.$refs.directConnection.isConnecting
      }
    }
  },
  watch: {
    $devices: {
      handler: function(newVal){
        console.log("$devices changed!", newVal)
      },
      deep: true
    },
  }
*/
}
</script>