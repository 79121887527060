<template>
    <v-dialog
    v-model="dialog"
    v-if="show"
    v-show="pointState"
    >
      <template v-slot:activator="{ on, attrs }">
      <a
        v-bind="attrs"
        v-on="on"
      >
        Reduce minimum interval restriction.
      </a>
      </template>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          reduce minimum
        </v-card-title>

        <v-card-subtitle>
          This is a premium feature.
        </v-card-subtitle>

        <v-card-text>          
          Require Atelier Ueda Points to unlock the limit for a certain period.
        </v-card-text>

        <v-card-text>
          Current Your Point:
          <div 
            class="ml-2"
            v-if="pointState.TotalPoints"
          >
            Available: {{pointState.TotalPoints.Available}} points
          </div>
          <div 
            class="ml-2"
            v-if="pointState.TotalPoints"
          >
            (Total: {{pointState.TotalPoints.Earned}}p - Used: {{pointState.TotalPoints.Used}}p)
          </div>
        </v-card-text>

        <v-card-text>
          The following options are available: {{radioGroup}}
          <v-radio-group
            v-model="radioGroup" 
            mandatory
          >
            <div class="d-flex flex-row">
              <v-radio
                value="1"
              ></v-radio> 
              <p class="text-caption">Decrease minimum interval to <b>60 seconds</b> for <b>one month</b> per <b>1 point</b>. </p>
            </div>
            <div class="d-flex flex-row">
              <v-radio
                value="2"
              ></v-radio> 
              <p class="text-caption">Decrease minimum interval to <b>10 seconds</b> for <b>one week</b> per <b>1 point</b>. </p>
            </div>
            <div class="d-flex flex-row">
              <v-radio
                value="3"
              ></v-radio>
              <p class="text-caption">Buy more Point, 1$ a Point. </p>
            </div>
          </v-radio-group>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="continuePoint"
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import IdToken from 'vue2-faui-user-fe/src/mixins/IdToken'

const getPointStateURL = "https://point-srv.uedasoft.com/getpointstate/"

export default {
  mixins: [IdToken],
  props:{
    show: {type: Boolean, default: false}
  },
  data: function(){
    return {
      dialog: false,
      radioGroup: 2,
      pointState:{},
    }
  },
  methods: {
    getPointState: async function(){
      if (this.$isLogin) {
        const idToken = await this.getIdToken()
        const url = getPointStateURL + idToken
        const res = await fetch(url)
        console.log(res)
        if (res.status == 200) {
          var data = await res.json();
          console.log("pointState",data)
          this.pointState = data
        }
      }
    },
    continuePoint: function() {
      alert("radioGroup: " + this.radioGroup)
      this.dialog = false
    }
  },
  mounted: async function(){
    if (this.$isLogin) {
      this.getPointState()
    }
    if ("ReduceMinimumInterval" in this.$route.query){
      if (this.$route.query.ReduceMinimumInterval == "open") {
        this.dialog = true
      }
    }
  },
}
</script>