import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Button from '../views/Button.vue'
import Apps from '../views/Apps.vue'
import Xterm from '../views/Xterm.vue'
import Xterm2 from '../views/Xterm2.vue'
import Devices from '@/views/Devices.vue'
import Management from '@/views/Management.vue'
import {AccountRoutes as AccountRoutes} from 'vue2-faui-user-fe/account'
//import {PurchaseRoutes as PurchaseRoutes} from 'vue2-faui-user-fe/purchase'
import DeleteAccountMessage from '@/components/Account/DeleteAccountMessage'
import Buy from '@/components/purchase/Buy'
import Document from './document.route.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/apps',
    name: 'Apps',
    component: Apps
  },
  {
    path: '/xterm',
    name: 'Xterm',
    component: Xterm
  },
  {
    path: '/xterm2',
    name: 'Xterm2',
    component: Xterm2,
    props: true,
  },
  {
    path: '/button',
    name: 'Button',
    component: Button
  },
  {
    path: '/devices',
    name: 'Devices',
    component: Devices
  },
  {
    path: '/management',
    name: 'Management',
    component: Management
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/buy',
    name: 'Buy',
    component: Buy
  },
  // Expand into routes array
  ...AccountRoutes.routes,
//  ...PurchaseRoutes.routes,
  ...Document.routes,
  /*
  ...PurchaseRoutes.routes.map(
    (e)=>{
      if (e.name == "purchase"){
        e.props = { routeAdding: PurchaseRoutes, routeDeleting: ["/purchaseBind"] }
//        e.props = { routeAdding: purchaseRoutes}
      }
      return e
    })
  */
]

AccountRoutes.routes.find(elm => elm.name == 'deleteaccount').props 
//  = {message:  Vue.extend({template: '<v-card-text class="red--text">Once you delete this account, there is no going back. Please be certain. けろっぴー、けろけろ</v-card-text>'})}
= {message: DeleteAccountMessage}

/*
PurchaseRoutes.routes.find(elm => elm.name == 'buy').props
//= {baseURL: 'https://connect-srv.uedasoft.com'}
= {
  baseURL: '',
  mainView: Buy
}
*/

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
