<!-- 
  wrapper of PaginatedList for Device List

  Dialogs:
    DeviceActionSelector
      Select "select" or "info"
    DeviceInfo:
      Show details.
-->

<template>
  <div>
    <PaginatedList
      name="Devices"
      ref="Devices"
      :getIdToken="this.getIdToken"
      :getTotalNumber="this.getTotalNumber"
      :getItems="this.getItems"
      :requestOne="this.requestOneOfNotActivated"
      @itemSelected="itemSelected"
    />
    <DeviceActionSelectorDialog
      ref="deviceActionSelector"
      @deviceSelected="selectedDeviceActionSelectorDialog"
    />
  </div>
</template>

<script>
//import DeviceInfoDialog from './DeviceInfoDialog'
import DeviceActionSelectorDialog from './DeviceActionSelector/DeviceActionSelectorDialog'
import {PaginatedList} from 'vuetifypaginatedlist'
import IdToken from 'vue2-faui-user-fe/src/mixins/IdToken'
// https://github.com/alexei/sprintf.js
var sprintf = require('sprintf-js').sprintf//,
//    vsprintf = require('sprintf-js').vsprintf

export default {
  components: {
//    DeviceInfoDialog,
    DeviceActionSelectorDialog,
    PaginatedList
  },
  mixins: [IdToken, ],

  data () {
    return {
      dialog: false,
      item: "",
    }
  },
  methods: {
    /**
     * For PaginatedList
     * 
     */
    itemSelected: function(item){
//      this.$deviceID = item.ID
//      this.$refs.device.open(item.ID)
      this.$refs.deviceActionSelector.open(item)

    },
    getTotalNumber: async function(idToken){
      const url=sprintf("https://connect-srv.uedasoft.com/gettotalnumberofdevices/%s",idToken)
      const res = await fetch(url)
      if (res.status == 200) {
        const data = await res.json()
        console.log("getTotalNumberOfItems", data)
        console.log("totalnumber", data.totalnumber)
        //this.totalNumber = data.totalnumber
        return data.totalnumber
      } else {
        return 0
      }
    },
    getItems: async function(idToken, itemsPerPage, page){
      const url=sprintf("https://connect-srv.uedasoft.com/getdevices/%d/%d/%s", 
                        itemsPerPage, 
                        (page -1)*itemsPerPage,
                        idToken)
      const res = await fetch(url)
      if (res.status == 200) {
        const data = await res.json()
        console.log("result", data)
        //this.items = data.devices
        return data.devices
      } else {
        return []
      }
    },
    requestOneOfNotActivated: async function(idToken){
      const url=sprintf("https://connect-srv.uedasoft.com/requestone/%s", 
                        idToken)
      const res = await fetch(url)
      if (res.status == 200) {
        const data = await res.json()
        console.log("result", data)
        //this.items = data.devices
        return [data.device]
      } else {
        return []
      }
    },


    /**
     * For Parent
     * 
     */
    requestOne(){
      this.$refs.Devices.callRequestOne()
    },

    /**
     * For DeviceActionSelectorDialog
     * 
     */
    selectedDeviceActionSelectorDialog(){
      /** emmit custom event */
      this.$emit('selectedDeviceActionSelectorDialog')
    },
    /**
     * For DeviceInfoDialog
     * 
     */
    selectDeviceInfoDialog(){
      /** emmit custom event */
      this.$emit('selectDeviceInfoDialog')
    },
    closeDeviceInfoDialog(){
      this.$emit('closeDeviceInfoDialog')
    },

    /**
     * For myself
     * 
     */
    updateItems(){
      this.$refs.Devices.updateItems()
    }
  },
  watch: {
    device: function(newVal){
      console.log("device", newVal)
    }
  },

}
</script>