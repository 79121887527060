<template>
  <div>
    <v-card flat>
      <v-card-title>
        Connection Status
      </v-card-title>
      <v-card-text>
        <ConnectionStatus class="mb-6"/>
      </v-card-text>
    </v-card>
    <v-tabs v-model="tab_model">
      <v-tab href="#terminal">Terminal</v-tab>
      <v-tab href="#command">Command</v-tab>
      <v-tab href="#Monitor">Monitor</v-tab>
      <v-tab href="#server">Server</v-tab>

      <v-tab-item value="tab-1">
      </v-tab-item>
    </v-tabs>

    <v-tabs-items v-model="tab_model">
      <v-tab-item value="terminal">
        <Xterm/>
      </v-tab-item>

      <v-tab-item value="command">
        <Command/>
      </v-tab-item>

    </v-tabs-items>
  </div>
</template>

<script>
import Xterm from '../views/Xterm.vue'
import Command from '@/components/Apps/Command.vue'
import ConnectionStatus from '@/components/Device/ConnectionStatus'

export default {
  components: {
    Xterm,
    Command,
    ConnectionStatus,
  },
  data: () => ({
    tab_model: "command",
    admins: [
      ['Management', 'mdi-account-multiple-outline'],
      ['Settings', 'mdi-cog-outline'],
    ],
    cruds: [
      ['Create', 'mdi-plus-outline'],
      ['Read', 'mdi-file-outline'],
      ['Update', 'mdi-update'],
      ['Delete', 'mdi-delete'],
    ],
  })
}
</script>

<style>

</style>