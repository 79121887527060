// for Global Mixin
// https://v2.vuejs.org/v2/guide/mixins.html?redirect=true#Global-Mixin

import Vue from 'vue'

export default {
  init: () => {
    let globalData = new Vue({
      data: { 
        $PeerConnection: new RTCPeerConnection({ // https://jsfiddle.net/9tsx15mg/90/
          iceServers: [
            {
              urls: 'stun:stun.l.google.com:19302'
            }
          ]
        }),
        $IceConnectionState: ""
      }
    });
    let computed = {
      $PeerConnection: {
        get: function () { return globalData.$data.$PeerConnection },
        set: function (newPeerConnection) { globalData.$data.$PeerConnection = newPeerConnection; }
      },
      $IceConnectionState: {
        get: function () { return globalData.$data.$IceConnectionState },
        set: function (newIceConnectionState) { globalData.$data.$IceConnectionState = newIceConnectionState; }
      }
  /*
      $server: {
        get: function () { return globalData.$data.$server },
      },
*/
    };
    return computed
  },
}