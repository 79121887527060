<template>
  <div>
    <v-dialog
      v-model="dialog"
      scrollable
      persistent
      eager
    >
      <v-card>
        <v-card-title>
          device: {{deviceID}}
        </v-card-title>
        <v-card-text>
          <v-btn
            block 
            class="mb-5"
            @click="select(item)"
          >
            select
          </v-btn>
          <v-btn
            block
            class="mt-5"
            @click="show(item)"
          >
            info
          </v-btn>
        </v-card-text>

        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="primary"
            text
            @click="cancel"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <DeviceInfoDialog
      ref="device"
    />
  </div>
</template>

<script>
import DeviceInfoDialog from './DeviceInfoDialog'
import Message from '@/mixins/message'
import Devices from '@/mixins/devices'

export default {
  components: {
    DeviceInfoDialog
  },
  mixins: [/*IdToken, */Message, Devices],
  data () {
    return {
      dialog: false,
      item: "",
//      deviceID: "",
    }
  },
    methods: {

    /**
     * Open this dialog
     * @param object item An item of the list.
     */
//    open(deviceID){
    open(item){
      console.log("item", item)

      /** initialize this dialog */
      this.item = item
//      this.deviceID = item.ID // temporal work around

      /** open dialog */
      this.dialog = true

      console.log("name", this.$refs.name)
      console.log("name", this.$refs)

    },

    /**
     * Handler of left hand side FAB
     */
    cancel(){
      this.dialog = false
    },

    /** 
     * Handler of the SELECT action
     */
    select(item){
      console.log("item", item)

      // create new $devices entry with this item
      this.createNewEntryWithStoredInfo(item)

      // set deviceID
      this.$deviceID = this.deviceID

      // save to localStrage
      localStorage.deviceID = this.deviceID

      // request last connect time of this device
      this.sendRequestLastConnectTimeMessage(this.$deviceID)

      // open deviceActionSelector
//      this.$refs.deviceActionSelector.open(this.$deviceID)

      console.log("$deviceID", this.$deviceID)

      /** close dialog */
      this.dialog = false

      /** emmit custom event */
      this.$emit('deviceSelected', this.deviceID)
    },

    /**
     * Handler of the SHOW button
     */
    show(item){
      console.log("item", item)

      this.$refs.device.open(this.item)
    }
  },
  computed: {
    deviceID(){
      return this.item.ID
    }
  }
}
</script>