<template>
<div>
  valid {{valid}}
  <v-form v-model="valid">
  <v-text-field
    v-model="newFrequency"
    type="number"
    label="Fetch Frequency"
    :rules="[rules.loanMin/*, rules.required*/]" 
    min="100"
    suffix="seconds"
  ></v-text-field>
  </v-form>
  <ReduceMinimumInterval
    :show="!valid"
  />
</div>
</template>

<script>
import Devices from '@/mixins/devices'
import ReduceMinimumInterval from '@/components/Device/Settings/FetchFrequency/ReduceMinimumInterval'

export default {
  mixins: [Devices],
  components: {
    ReduceMinimumInterval
  },
  data: function(){
    return {
      valid: true,
//      device: "",
      newFrequency: "",
      item: "",
      rules: {
//        required: v => !!v || 'Required.',
        loanMin: v => (v && v >= 100) || 'minimum interval is 100 min.',
      }
    }
  },
  methods: {
    update(updatingSettings) {
      // todo
      let device = this.$devices[this.$deviceID]
      console.log("device", device)
      if (this.newFrequency != device.storedInfo.Attr.runtime_settings.interval){
        updatingSettings.push({Path:"$.runtime_settings.interval", Value:Number(this.newFrequency)})
      }
    },
  },
  mounted(){
    if ("newFrequency" in this.$route.query){
      this.newFrequency = this.$route.query.newFrequency
    }
  },
  watch: {
    $deviceID: function(){
      if (this.$device.storedInfo.Attr && this.$device.storedInfo.Attr.runtime_settings){
        console.log("runtime_settings", this.$device.storedInfo.Attr.runtime_settings)
        this.newFrequency = this.$device.storedInfo.Attr.runtime_settings.interval
      }
    }
  },
}
</script>