<template>
  <div>
<!--    connectionState: {{$IceConnectionState}} -->

    <v-progress-linear
      v-if="progressLinear"
      indeterminate
      color="primary"
    ></v-progress-linear>

<!--
        <v-btn
          absolute
          dark
          bottom
          left
          rounded
          color="primary"
          @click="peerdisconnect"
        >
          disconnect
        </v-btn>

        <v-btn
          absolute
          dark
          bottom
          right
          rounded
          color="primary"
          @click="signalingStart"
        >
          connect
        </v-btn>
-->
<!--
        <v-btn
          absolute
          dark
          bottom
          right
          rounded
          color="primary"
          @click="sendToChannel('ls -la\nps\n')"
        >
          start
        </v-btn>
-->
    <div id="Xterm" ref="Xterm"></div>
    <Xterm ref="xterm"/>
    $isLogin: {{$isLogin}}

  </div>
</template>

<script>
//import Xterm from '@/mixins/xterm'
import Signaling from '@/mixins/signaling'
import Xterm from '@/components/Xterm'

export default {
  components: {
    Xterm,
  },
  data: () => ({
    progressLinear: false,
  }),
  methods:{
    sendToChannel(cmdStr){
      console.log("this",this)
      this.$refs.xterm.sendToChannel(cmdStr)
    }
  },
  mixins: [/*Xterm, */Signaling]

}
</script>
